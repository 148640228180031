import React from 'react';
import './about.css';
import ME from '../../assets/about/david_laidlaw_headshot.jpg';
// import SUNSET from '../../assets/block-sunset-prints.webp';
import {FaAward} from 'react-icons/fa';
import {TfiDashboard} from 'react-icons/tfi';
import {FiUsers} from 'react-icons/fi';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon"/>
              <h5>Experience</h5>
              <small>12+ Years Experience</small>
            </article>
            <article className="about__card">
              <TfiDashboard className="about__icon"/>
              <h5>Dashboards</h5>
              <small>70+ Completed</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon"/>
              <h5>Clients</h5>
              <small>50+ Worldwide</small>
            </article>
          </div>
          <p>
            I'm a Developer of dashboards, apps, websites and much more - including a background in all things media. My most recent work includes developing complex BI dashboards and custom data connectors for various clients using Power BI Embedded, Power Automate, Azure AD, Dataverse and connecting hundreds of services utilizing REST APIs & direct database connections. I also build apps from wireframes/mockups/prototypes all the way to full database backend setup and app rollout on various platforms (Windows, macOS, iOS, Android, Web). Throughout my 12+ years experience I've also enjoyed developing sophisticated websites/blogs. I'm proficient in Microsoft Power Platform (Power BI, Power Automate, Power Apps, Power Virtual Agents), ETL processes, DAX & M Language, Dataverse, AAD, SQL, JavaScript, React, jQuery, HTML5, CSS3, PHP, WordPress, Klipfolio, Figma, Bootstrap, Node.js, Express, MySQL, MongoDB, Firebase, and REST APIs.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About