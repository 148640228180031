import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './portfolio.css';
// import '../modal/modal.css';
// import Modal from '../modal/Modal';

import IMG1 from '../../assets/portfolio/ackert_kpi_dashboards_powerbi_20220328_5_thumb.png';
import IMG2 from '../../assets/portfolio/ackert_kpi_dashboards_powerbi_20220202_5_thumb.png';
import IMG3 from '../../assets/portfolio/ackert_website_20220901_1_thumb.png';
import IMG4 from '../../assets/portfolio/ackert_pipelineplus_targets_1_thumb.png';
import IMG5 from '../../assets/portfolio/ackert_driver_prototype_18_thumb.png';
import IMG6 from '../../assets/portfolio/accelerate_hc_website_1_thumb.png';


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Power BI Dashboards',
    demo: 'https://www.ackertinc.com/platform/',
    details: '#0'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Klipfolio Dashboards',
    demo: 'https://www.klipfolio.com',
    details: '#0'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Ackert Inc Website',
    demo: 'https://web.archive.org/web/20210411200526/https://www.ackertinc.com/',
    details: '#0'
    
  },
  {
    id: 4,
    image: IMG4,
    title: 'PipelinePLUS: Sales Pipeline App',
    demo: 'https://www.ackertinc.com/platform/',
    details: '#0'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Driver: Business Planner App',
    demo: 'https://www.ackertinc.com/platform/',
    details: '#0'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Accelerate HC Website',
    demo: 'https://web.archive.org/web/20211203005943/https://www.acceleratehc.com/',
    details: '#0'
    
  },
]

// const Portfolio_Modal = () => {
 
function Portfolio_Modal() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow (false);
  const handleShow = () => setShow(true);

    return (
      <section id='portfolio'>
        <h5>My Recent Work</h5>
        <h2>Portfolio</h2>
        <div className='container portfolio__container'>
          {
            data.map(({id, image, title, demo, details}) => {
              return (
                <article key={id} className='portfolio__item'>
                  <div className='portfolio__item-image'>
                    <img src={image} alt={title} />
                  </div>
                  <h3>{title}</h3>
                  <div className='portfolio__item-cta'>
                  {/* <Button variant="primary" onClick={handleShow} className='btn'>
                    Modal
                  </Button> */}
                    <a href={details} className='btn' rel='noreferrer'>Details</a>
                    <a href={demo} className='btn btn-primary' target='_blank' rel='noreferrer'>Live Demo</a>
                  </div>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleClose}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </article>
              )
            }
            )
          }
          
        </div>
      </section>
    )
}


export default Portfolio_Modal