import React from 'react';
import './footer.css';
import {BsLinkedin} from 'react-icons/bs';
import {FaGithub} from 'react-icons/fa';
import {FaCodepen} from 'react-icons/fa';
import {TfiJsfiddle} from 'react-icons/tfi';


const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>David Laidlaw</a>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className='footer__socials'>
      <a href="https://www.linkedin.com/in/davidlaidlaw/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
      <a href="https://github.com/davelaid" target="_blank" rel="noreferrer"><FaGithub/></a>
      <a href="https://codepen.io/davelaid" target="_blank" rel="noreferrer"><FaCodepen/></a>
      <a href="https://jsfiddle.net/user/DaveLaid/fiddles/" target="_blank" rel="noreferrer"><TfiJsfiddle/></a>
      </div>

      <div className='footer__copyright'>
        <small>&copy; David Laidlaw. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer
