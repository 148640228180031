import React from 'react';
import './testimonials.css';
import AVATAR1 from '../../assets/testimonial_david_ackert.jpg';
import AVATAR2 from '../../assets/testimonial_olivia_watson.jpg';
import AVATAR3 from '../../assets/testimonial_craig_streaman.jpg';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
//Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVATAR1,
    name: 'David Ackert - CEO',
    review: "David Laidlaw worked at Ackert Inc. for nearly five years. He helped design websites, products, and numerous systems during his tenure at the company. He was instrumental in setting up and maintaining our KPI dashboards, and keeping those clients happy. He was always level-headed in his approach, brought a pleasant demeanor to the culture and demonstrated dedication to the company."
  },
  {
    avatar: AVATAR2,
    name: 'Olivia Watson - Senior Marketing Manager',
    review: "I worked with Dave for years at a B2B SaaS company, and it was an absolute pleasure working with him. He took our platforms from 'minimum viable product' to sophisticated, high-value sales enablement applications that helped our clients generate huge ROI. As a marketer, he made my job easy - our platforms were aesthetically beautiful, easy to communicate, and had intuitive UX. None of that would've been possible without Dave's skilled work. I miss working with him and highly recommend him for any position in which he is interested!"
  },
  {
    avatar: AVATAR3,
    name: 'Craig Streaman - Digital Marketer',
    review: "David is a strong worker with a great sense of priority and ethics. In a job where the opportunity to take advantage of customers is evident, he has maintained his morals, while working quickly and efficiently."
  }
  // ,
  // {
  //   avatar: AVATAR3,
  //   name: 'Person 3',
  //   review: 'kjlasdj lkjfl kjasj ljlkjalsjf jja lk lsafj laj  lasdl al jfla j al fjas jflajsfl kjasl aljf lajfljalf jajflajfljaslfjslajfljsa.'
  // },
  // {
  //   avatar: AVATAR4,
  //   name: 'Person 4',
  //   review: 'kjlasdj lkjfl kjasj ljlkjalsjf jja lk lsafj laj  lasdl al jfla j al fjas jflajsfl kjasl aljf lajfljalf jajflajfljaslfjslajfljsa.'
  // }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Reviews from Clients & Colleagues</h5>
      <h2>Testimonials</h2>
      <Swiper className='container testimonials__container'
        // install Swiper modules
        modules={[Pagination, Navigation]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true}}
      >
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__avatar'>
                  <img src={avatar} alt={name + ' headshot'} />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials
