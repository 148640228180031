import React from 'react';
import './header.css';
import CTA from './CTA';
// import BLUEIMG from '../../assets/contact_background.jpg';
// import HeaderSocials from './HeaderSocials';

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>David Laidlaw</h1>
        <h5 className="text-light">BI Developer &nbsp;&nbsp;|&nbsp;&nbsp; Sr Software Engineer &nbsp;&nbsp;|&nbsp;&nbsp; Web Developer</h5>
        <CTA />
        {/* <HeaderSocials /> */}
        <div className='sunset__container'>
          <div className='sunset'></div>
          <h2 className='sunset__text'>
            <a href='#portfolio'>PORTFOLIO</a>
          </h2>
          <div className='water'>
            <div className='wave_width_5 wave_height_1 wave_location_5 wave_color_2'></div>
            <div className='wave_width_6 wave_height_2 wave_location_6 wave_color_3'></div>
            <div className='wave_width_4 wave_height_3 wave_location_4 wave_color_2'></div>
            <div className='wave_width_1 wave_height_1 wave_location_1 wave_color_1'></div>
            <div className='wave_width_3 wave_height_2 wave_location_2 wave_color_4'></div>
            <div className='wave_width_1 wave_height_3 wave_location_1 wave_color_5'></div>
            <div className='wave_width_2 wave_height_2 wave_location_6 wave_color_1'></div>
            <div className='wave_width_3 wave_height_1 wave_location_4 wave_color_5'></div>
            <div className='wave_width_4 wave_height_4 wave_location_3 wave_color_3'></div>
            <div className='wave_width_5 wave_height_5 wave_location_5 wave_color_2'></div>
            <div className='wave_width_6 wave_height_2 wave_location_2 wave_color_2'></div>
            <div className='wave_width_4 wave_height_1 wave_location_1 wave_color_6'></div>
            <div className='wave_width_3 wave_height_3 wave_location_6 wave_color_4'></div>
            <div className='wave_width_2 wave_height_4 wave_location_4 wave_color_1'></div>
            <div className='wave_width_1 wave_height_2 wave_location_3 wave_color_2'></div>
            <div className='wave_width_6 wave_height_1 wave_location_5 wave_color_3'></div>
            <div className='wave_width_5 wave_height_6 wave_location_2 wave_color_5'></div>
            <div className='wave_width_5 wave_height_5 wave_location_5 wave_color_6'></div>
            <div className='wave_width_6 wave_height_6 wave_location_6 wave_color_3'></div>
            <div className='wave_width_4 wave_height_4 wave_location_4 wave_color_2'></div>
            <div className='wave_width_1 wave_height_1 wave_location_1 wave_color_1'></div>
            <div className='wave_width_3 wave_height_3 wave_location_2 wave_color_4'></div>
            <div className='wave_width_1 wave_height_3 wave_location_1 wave_color_5'></div>
            <div className='wave_width_3 wave_height_4 wave_location_5 wave_color_2'></div>
            <div className='wave_width_4 wave_height_5 wave_location_6 wave_color_3'></div>
            <div className='wave_width_2 wave_height_6 wave_location_2 wave_color_2'></div>
            <div className='wave_width_5 wave_height_3 wave_location_5 wave_color_2'></div>
            <div className='wave_width_1 wave_height_4 wave_location_3 wave_color_1'></div>
            <div className='wave_width_5 wave_height_4 wave_location_4 wave_color_3'></div>
            <div className='wave_width_5 wave_height_6 wave_location_2 wave_color_5'></div>
            <div className='wave_width_5 wave_height_5 wave_location_5 wave_color_1'></div>
            <div className='wave_width_6 wave_height_4 wave_location_6 wave_color_6'></div>
            <div className='wave_width_4 wave_height_4 wave_location_4 wave_color_2'></div>
            <div className='wave_width_1 wave_height_1 wave_location_1 wave_color_1'></div>
            <div className='wave_width_3 wave_height_3 wave_location_2 wave_color_4'></div>
          </div>
          {/* <div className="me">
            <img src={BLUEIMG} alt="me" />
          </div> */}
        </div>
        

        {/* <a href="#contact" className="scroll__down">Scroll Down</a> */}
      </div>
    </header>
  )
}

export default Header
