import React from 'react';
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md';
// import {RiMessengerLine} from 'react-icons/ri';
import {RiLinkedinBoxLine} from 'react-icons/ri';
// import {BsWhatsapp} from 'react-icons/bs';
import { useRef } from 'react';
import emailjs from 'emailjs-com';


const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8hgnn7w', 'template_c8kparw', form.current, 'BGnqItij4R2QM7J12')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>davidlaidlaw15@gmail.com</h5>
            <a href='mailto:davidlaidlaw15@gmail.com' target='_blank' rel='noreferrer'>Send a Message</a>
          </article>
          <article className='contact__option'>
            <RiLinkedinBoxLine className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5>davidlaidlaw</h5>
            <a href='https://www.linkedin.com/in/davidlaidlaw/' target='_blank' rel='noreferrer'>Connect with me on LinkedIn</a>
          </article>
          {/* <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>davidlaidlaw</h5>
            <a href='https://m.me/davidlaidlaw' target='_blank' rel='noreferrer'>Send a Message</a>
          </article> */}
          {/* <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+123456789</h5>
            <a href='https://api.whatsapp.com/send?phone=+123456789' target='_blank' rel='noreferrer'>Send a Message</a>
          </article> */}
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required />
          <input type='email' name='email' placeholder='Your Email' required />
          <textarea name='message' rows='7' placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact