import React from 'react';
import './services.css';
import {BiCheck} from 'react-icons/bi';

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>Dashboard Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Build complex reporting dashboards that are easy to understand.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Build custom connectors to connect your various services and platforms.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Help realize your sales & business development KPI goals with automated flows.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Setup Microsoft tenant and Dataverse to securely store your data (if preferred).</p>
            </li>
          </ul>
        </article>
        {/* END OF DASHBOARD DEVELOPMENT */}
        <article className='service'>
          <div className='service__head'>
            <h3>Web Design & Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Create attractive graphic designs utilizing CSS and/or JavaScript.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Setup and maintain analytics tools to monitor website traffic (Google Analytics).</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Build forms, connect databases, and perform quality assurance tests.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Setup email campaign and communication management system.</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
        <article className='service'>
          <div className='service__head'>
            <h3>App Builds</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Create architecture and conceptual design for React apps.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Package apps for web and native devices (Windows, macOS, iOS, Android).</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Design user interface elements to create a cohesive theme throughout app.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Develop plans and walk-throughs for proper user experience.</p>
            </li>
          </ul>
        </article>
        {/* END OF APP BUILDS */}
      </div>
    </section>
  )
}

export default Services
